<template>
  <div class="flex relative h-full min-h-0 border-l bg-gray-50 border-gray-200 flex-col">
    <PanelSideComments
      v-if="expandedComments"
      :comments="comments"
      :users="users"
      show-navigation
      @close="$emit('close')"
      @go-back="expandedComments = false"
      @edit="$emit('edit', $event)"
      @delete="$emit('delete', $event)"
      @new-comment="$emit('new-comment')"
    />
    <div v-else class="h-full flex flex-col gap-4 min-h-0 w-full py-4 px-4">
      <div class="flex text-lg font-bold">
        <div class="flex-1">{{ block.type == 'document' ? $t('document_details') : $t('block_details') }}</div>
        <fw-button class="-mr-2" type="link-light" size="xs" @click.native="$emit('close')">
          <fw-icon-close class="w-5 h-5" />
        </fw-button>
      </div>
      <div v-if="block.type != 'document'" class="flex items-center gap-4 mb-1">
        <BlockIcon size="lg" class="text-gray-400" :type="block.type" />
        <div class="flex-1">
          <fw-heading size="h4">{{ block.type }}</fw-heading>
        </div>
      </div>
      <div :key="localKey" class="comments-scroll-sidebar flex-1 overflow-hidden relative">
        <fw-heading size="label" :counter="comments.length">{{ $t('comments') }}</fw-heading>
        <fw-button
          v-if="canComment && state != 'closed'"
          class="w-full my-3"
          type="dashed"
          @click.native="$emit('new-comment')"
        >
          {{ $t('new_comment') }}
        </fw-button>
        <fw-message v-if="state == 'closed'" type="info" class="text-sm my-3">
          {{ $t('comments_closed') }}
        </fw-message>
        <div v-if="loading" class="text-gray-300 text-center py-14 rounded-b-xl flex justify-center pt-4">
          <fw-icon-loading class="w-8 h-8" />
        </div>
        <div v-else-if="comments.length == 0" class="py-5 text-center text-sm text-gray-400 pt-4">
          {{ $t('no_comments') }}
        </div>
        <RecordCommentEditor
          v-for="comment in comments"
          :key="comment.key"
          minimal
          :post="comment"
          :users="users"
          class="mb-1.5"
          :type="comment.type"
          @edit="$emit('edit-comment', comment)"
          @delete="$emit('delete-comment', comment)"
          @open-comment="$emit('open-comment', comment)"
        />
        <div
          v-if="hasOverflow"
          class="absolute cursor-pointer bottom-0 left-0 text-xs font-semibold text-gray-500 pt-12 right-0 text-center pb-3 from-gray-50 via-gray-50 to-transparent bg-gradient-to-t"
          @click="expandedComments = true"
        >
          {{ $t('view_all_comments') }}
        </div>
      </div>
      <div class="border-t flex flex-col gap-0.5 border-gray-300 pt-4 text-gray-600">
        <fw-label>{{ $t('stats') }}</fw-label>
        <div class="flex items-center text-sm">
          <div class="flex-1 text-gray-600">{{ $t('words') }}</div>
          <div>{{ block.words }}</div>
        </div>
        <div class="flex items-center text-sm">
          <div class="flex-1 text-gray-600">{{ $t('chars') }}</div>
          <div>{{ block.characters }}</div>
        </div>
        <div class="flex items-center text-sm">
          <div class="flex-1 text-gray-600">{{ $t('reading_time') }}</div>
          <div>{{ convertNumberOfWordsToTime(block.words) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import BlockIcon from '@/fw-modules/fw-core-vue/pages/components/blocks/BlockIcon.vue'
import RecordCommentEditor from '@/fw-modules/fw-core-vue/pages/components/records/RecordCommentEditor.vue'
import PanelSideComments from '@/fw-modules/fw-core-vue/pages/components/panels/PanelSideComments.vue'
export default {
  name: 'SidebarBlockDetail',
  components: {
    BlockIcon,
    RecordCommentEditor,
    PanelSideComments,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
    comments: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Object,
      default: () => ({}),
    },
    myrole: {
      type: String,
      required: true,
    },
    version: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      hasOverflow: false,
      expandedComments: false,
      localKey: 0,
      limitedComments: [],
    }
  },
  computed: {
    state() {
      return this.version ? this.version.state : null
    },
  },
  watch: {
    block: {
      deep: true,
      immediate: true,
      handler() {
        this.detectOverflow()
      },
    },
    comments: {
      deep: true,
      immediate: true,
      handler() {
        console.log('comments changed!!! =========', this.comments)
        this.$forceUpdate()
        this.detectOverflow()
      },
    },
  },
  mounted() {
    this.detectOverflow()
  },
  methods: {
    canComment() {
      return (
        this.myrole === 'owner' || this.myrole === 'manager' || this.myrole === 'editor' || this.myrole === 'reviewer'
      )
    },
    detectOverflow() {
      //this.limitedComments = this.comments.slice(0, 5)
      Vue.nextTick(() => {
        //detect if there are more comments than space available
        let element = document.querySelector('.comments-scroll-sidebar')
        if (element) {
          this.hasOverflow = element.scrollHeight > element.clientHeight
        } else {
          this.hasOverflow = false
        }
      })
    },
    convertNumberOfWordsToTime(numberOfWords) {
      let minutes = Math.floor(numberOfWords / 110)
      let seconds = Math.ceil((numberOfWords % 110) / (110 / 60))
      return `${minutes}m ${seconds}s`
    },
  },
}
</script>

<i18n>
  {
    "pt": {
      "block_details": "Detalhes do bloco",
      "document_details": "Detalhes da página",
      "comments": "Comentários",
      "new_comment": "Novo comentário",
      "no_comments": "Sem comentários",
      "view_all_comments": "Ver todos os comentários",
      "stats": "Estatísticas",
      "words": "Palavras",
      "chars": "Caracteres",
      "reading_time": "Tempo de leitura",
      "comments_closed": "Novos comentários estão bloqueados para esta versão"
    },
    "en": {
      "block_details": "Block details",
      "document_details": "Page details",
      "comments": "Comments",
      "new_comment": "New comment",
      "no_comments": "No comments",
      "view_all_comments": "View all comments",
      "stats": "Stats",
      "words": "Words",
      "chars": "Characters",
      "reading_time": "Reading time",
      "comments_closed": "New comments are blocked for this version"
    }
  }
</i18n>
